import React from "react";
import {Route, Routes} from "react-router-dom";
import HomeFive from "../themes/index-5/HomeFive";
import ScrollToTop from "./ScrollToTop";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<HomeFive/>}/>
        <Route path="*" element={<HomeFive/>}/>
      </Routes>
    </>
  );
};

export default AppRoutes;
