import React from "react";

export default function AboutGoal() {
  return (
    <>
      <section id="nuestro-objetivo" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="about-content-left section-heading">
                <span className="text-uppercase color-secondary sub-title">
                  Sobre nuestro objetivo
                </span>
                <h2>
                  Te ayudamos a impulsar tu negocio más rápido
                </h2>
                <p>
                  Utilizamos tecnología de vanguardia para ayudarte a construir aplicaciones increíbles. Nuestro
                  objetivo es ayudarte a impulsar tu negocio más rápido.
                </p>

                <div className="single-feature mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <span className="ti-vector rounded mr-3 icon icon-color-1"></span>
                    <div className="mb-0 h5">
                      Fácil de usar
                    </div>
                  </div>
                  <p>
                    La idea es que sea fácil de usar y que puedas empezar a trabajar en tu aplicación de inmediato.
                  </p>
                </div>
                <div className="single-feature mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <span className="ti-dashboard rounded mr-3 icon icon-color-2"></span>
                    <div className="mb-0 h5">
                      Aumenta la conversión
                    </div>
                  </div>
                  <p>
                    Potencia tu negocio y aumenta la conversión con nuestras aplicaciones.
                  </p>
                </div>

                <div className="single-feature mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <span className="ti-alarm-clock rounded mr-3 icon icon-color-3"></span>
                    <div className="mb-0 h5">Mantenimiento</div>
                  </div>
                  <p>
                    Realizamos un seguimiento de tus aplicaciones y te proporcionamos información valiosa.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-content-right">
                <img
                  src="assets/img/about-img.png"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
