import React from "react";

export default function Contact({bgColor}) {
  return (
    <>
      <section
        id="contacto"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-8">
              <div className="section-heading">
                <h2>
                  Contacta con nosotros
                </h2>
                <p>
                  Es muy fácil ponerse en contacto con nosotros. Simplemente envíenos un correo electrónico. Estamos
                  aquí para ayudarte con cualquier pregunta que puedas tener.
                </p>
              </div>
              <div className="footer-address">
                <p className={'mb-0'}>
                  Ubicación
                </p>
                <p>Hermosillo Sonora, México</p>
                <ul>
                  <li>
                    <span>
                      Correo electrónico:
                    </span>
                  </li>
                  <li>
                    <a style={{color: '#707070'}} href="mailto:contacto@tecnologiasintech.com">
                      contacto@tecnologiasintech.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
