import React from "react";
import AboutGoal from "../../components/about/AboutGoal";
import Clients from "../../components/brand-logo/Clients";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PromoTwo from "../../components/promo/PromoTwo";
import HeroFive from "./HeroFive";

export default function HomeFive() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroFive />
      <Clients />
      <PromoTwo />
      <AboutGoal />
      <Contact />
      <Footer />
    </Layout>
  );
}
