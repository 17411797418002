import React from "react";

export default function Footer({space}) {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${space ? "pt-150" : "pt-60"}`}
          style={{
            background: "#454545"
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <div className="h5 mb-3 text-white">Sobre nosotros</div>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <span>                 Somos una empresa tecnológica mexicana dedicada al desarrollo de software a la medida. Somos un
                            equipo enfocado en proporcionar soluciones en sistemas y tecnología. Desarrollamos herramientas para
                            administrar, evaluar y mejorar procesos internos y externos de las empresas.</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <div className="h5 mb-3 text-white">Contacto</div>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="#about">contacto@tecnologiasintech.com</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <div className="h5 mb-3 text-white">Ubicación</div>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-location-pin mr-2"></span>
                          Hermosillo Sonora, México
                          <br/>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      Tecnologías Intech ® Todos los derechos reservados
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
