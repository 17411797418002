export default function HeroFive() {
  return (
    <>
      <section className="hero-section position-relative min-vh-100 background-video-overly flex-column d-flex justify-content-center" id={'inicio'}>
        <img
          className={'fit-cover w-100 h-100 position-absolute z--1'}
          src={"assets/img/home.jpg"}
          alt={'home'}
        />
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-9 col-lg-9">
              <div className="hero-content-left text-white text-center mt-5 ptb-100">
                <h1 className="text-white">
                  Construye lo que quieras con nosotros
                </h1>
                <p className="lead">
                  Ofrecemos desarrollo de software, aplicaciones móviles, diseño web. Encuentre aquí detalles sobre
                  nuestra empresa.
                </p>

                <a href="#contacto" className="btn google-play-btn">
                  Contacta con nosotros
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
